import { Button, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import React, { useCallback, useMemo } from "react";
import { listTimeZones } from "timezone-support";
import * as yup from "yup";
import { Machine } from "../../types/Machine";
import { GetEmailFromUserName } from "../../utility/FormUtility";
import CommentsSectionPresentation from "./Discussion/CommentsSectionPresentation";
import { AutocompleteInput } from "./Inputs/AutocompleteInput";
import { FormInput } from "./Inputs/FormInput";
import UserAutocomplete from "./Inputs/UserAutocomplete";

const useStyles = makeStyles((theme) => ({
  buttonSection: {
    marginTop: theme.spacing(3),
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
  commentSectionDivider: {
    marginTop: 5,
    marginBottom: 10,
  },
  commentSectionTextField: {
    marginBottom: 5,
  },
}));

interface Props {
  machine: Machine;
  machineTypes: Array<string>;
  countries: Array<string>;
  users: Array<string>;
  asms: Array<string>;
  isPageLoading: boolean;
  isMachineLocked: boolean;
  onSubmit: (machine: Machine) => void;
  onCommentDeleted: (id: string) => void;
}

export default function EditMachineForm({
  machine,
  machineTypes,
  countries,
  users,
  asms,
  isPageLoading,
  isMachineLocked,
  onSubmit,
  onCommentDeleted,
}: Props) {
  const classes = useStyles();
  const timezones = listTimeZones();
  const commentMaxLength = 256;
  const isDisabled = useMemo(
    () => isPageLoading || isMachineLocked,
    [isPageLoading, isMachineLocked]
  );

  const validationSchema = yup.object({
    company: yup.string().required("Required"),
    ownerEmail: yup
      .string()
      .transform((value, _) => GetEmailFromUserName(value))
      .required("Required"),
    asmEmail: yup
      .string()
      .oneOf(asms, "The selected user is not a valid ASM")
      .required("Required"),
    timezone: yup
      .string()
      .oneOf(timezones, "The selected timezone is not a valid timezone")
      .required("Required"),
    place: yup.string().required("Required"),
    debtorNumber: yup.string().required("Required"),
    machineType: yup
      .string()
      .oneOf(machineTypes, "The selected machine type is not valid")
      .required("Required"),
    country: yup.string().oneOf(countries, "The selected country is not valid"),
    latitude: yup
      .number()
      .typeError(
        "This is not a number. Use '.' instead of ',' to denote latitude."
      ),
    longitude: yup
      .number()
      .typeError(
        "This is not a number. Use '.' instead of ',' to denote latitude."
      ),
  });

  const handleSubmit = useCallback(
    (values, _) => {
      onSubmit(values);
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: {
      ...machine,
      fullAddress: machine.fullAddress ?? "",
      company: machine.company ?? "",
      debtorNumber: machine.debtorNumber ?? "",
      machineType: machine.machineType?.toString() ?? machineTypes[0] ?? "",
      comment: machine.comment ?? "",
      timezone: machine.timezone ?? "",
      asmEmail: machine.asmEmail ?? "",
      latitude: machine.latitude ?? undefined,
      longitude: machine.longitude ?? undefined,
      place: machine.place ?? "",
      country: machine.country ?? "",
    },
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true, // reset form state (dirty, valid etc.) after submit
  });

  const handleResetForm = useCallback(() => {
    formik.resetForm();
  }, [formik]);

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Typography variant="h5">Owner Details</Typography>
              <FormInput
                id="company"
                labelText="Company Name *"
                disabled={isDisabled}
                value={formik.values.company}
                touched={formik.touched.company}
                error={formik.errors.company}
                formik={formik}
              />

              <UserAutocomplete
                isPageLoading={isPageLoading}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                users={users}
                handleChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
                initialValues={formik.initialValues}
                fieldId={"ownerEmail"}
                isDisabled={isMachineLocked}
              />

              <FormInput
                id="place"
                labelText="Place *"
                disabled={isDisabled}
                value={formik.values.place}
                touched={formik.touched.place}
                error={formik.errors.place}
                formik={formik}
              />

              <AutocompleteInput
                id={"country"}
                labelText={"Country *"}
                disabled={isDisabled}
                selectedOption={formik.values.country}
                options={countries}
                formik={formik}
                error={formik.errors.country}
                touched={formik.touched.country}
              />

              <FormInput
                id="fullAddress"
                labelText="Full Address"
                disabled={isDisabled}
                value={formik.values.fullAddress}
                touched={formik.touched.fullAddress}
                error={formik.errors.fullAddress}
                formik={formik}
              />

              <FormInput
                id="latitude"
                labelText="Latitude"
                disabled={isDisabled}
                value={formik.values.latitude}
                touched={formik.touched.latitude}
                error={formik.errors.latitude}
                formik={formik}
              />

              <FormInput
                id="longitude"
                labelText="Longitude"
                disabled={isDisabled}
                value={formik.values.longitude}
                touched={formik.touched.longitude}
                error={formik.errors.longitude}
                formik={formik}
              />

              <FormInput
                id="debtorNumber"
                labelText="Debtor number *"
                disabled={isDisabled}
                value={formik.values.debtorNumber}
                touched={formik.touched.debtorNumber}
                error={formik.errors.debtorNumber}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="h5">Machine Details</Typography>
              <AutocompleteInput
                id={"machineType"}
                labelText={"Machine Type *"}
                disabled={isDisabled}
                selectedOption={formik.values.machineType}
                options={machineTypes}
                formik={formik}
                error={formik.errors.machineType}
                touched={formik.touched.machineType}
              />

              <UserAutocomplete
                isPageLoading={isPageLoading}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
                alwaysDisplayError={Boolean(formik.values.asmEmail)}
                users={asms}
                handleChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
                initialValues={formik.initialValues}
                fieldId={"asmEmail"}
                isDisabled={isMachineLocked}
              />

              <AutocompleteInput
                id={"timezone"}
                labelText={"Timezone *"}
                disabled={isDisabled}
                selectedOption={formik.values.timezone}
                options={timezones}
                formik={formik}
                error={formik.errors.timezone}
                touched={formik.touched.timezone}
              />

              <Typography variant="subtitle1">Discussion</Typography>
              <Divider className={classes.commentSectionDivider} />

              <FormInput
                id="comment"
                labelText={`Add a comment. A maximum of ${commentMaxLength} characters is allowed`}
                multiline
                rows={3}
                className={classes.commentSectionTextField}
                inputProps={{
                  maxLength: commentMaxLength,
                }}
                disabled={isDisabled}
                value={formik.values.comment}
                touched={formik.touched.comment}
                error={formik.errors.comment}
                formik={formik}
              />

              <CommentsSectionPresentation
                Comments={formik.values.comments}
                handleDeletion={onCommentDeleted}
              />
            </Grid>
            {!isMachineLocked && (
              <Grid item xs={12}>
                <div className={classes.buttonSection}>
                  <Divider />
                  <div className={classes.buttonGroup}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        !formik.dirty || !formik.isValid || isPageLoading
                      }
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleResetForm}
                      disabled={!formik.dirty || isPageLoading}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    </>
  );
}
