export function IsValidEmailAddress(emailAddress: string): boolean {
  return /^[A-Z0-9._%+-]+('[A-Z0-9._%+-]+)*@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
    emailAddress
  );
}

/**
 * The User is displayed as FirstName LastName (Email)
 * This function returns the Email between parentheses.
 */
export function GetEmailFromUserName(userName: string | null): string {
  if (!userName) return "";
  if (IsValidEmailAddress(userName)) return userName;
  const start = userName.indexOf("(");
  const end = userName.indexOf(")");
  return userName.substr(start + 1, end - start - 1);
}
